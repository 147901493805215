//import Carousel from "./carousel";
import banner6451 from "./assets/sepolia6x45.png";

function App() {
  return (
    <div className="bg-background flex h-screen justify-center items-center">
      <a href="https://ethereum-sepolia-faucet.cryptonomics.city">
        <img src={banner6451} alt="6x45x1" className="" />
      </a>
    </div>
  );
}

export default App;
